import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { DestinationsComponent } from './pages/destinations/destinations.component';
import { DetailComponent } from './pages/destinations/detail/detail.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { CloseMenuOnClickDirective } from './directives/close-menu-on-click.directive';
import { AccountComponent } from './pages/account/account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './pages/account/login/login.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { ProfileComponent } from './pages/account/profile/profile.component';
import { LogoutButtonDirective } from './directives/logout-button.directive';
import { MycardComponent } from './pages/account/mycard/mycard.component';
import { FavoritesComponent } from './pages/account/favorites/favorites.component';
import { CitiesComponent } from './pages/destinations/cities/cities.component';
import { NearbyComponent } from './pages/destinations/nearby/nearby.component';
import { AgmCoreModule } from '@agm/core';
import { SearchFilterPipe } from './components/header/search-filter.pipe';
import { FaqComponent } from './pages/faq/faq.component';
import { InfoComponent } from './pages/info/info.component';
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { TechnischerSupportComponent } from './pages/technischer-support/technischer-support.component';
import { AgbComponent } from './pages/agb/agb.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  APP_NAME,
  APP_VERSION,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';

import { PrivacyComponent } from './pages/privacy/privacy.component';
import { MessagingService } from './services/messaging.service';
import { SettingsComponent } from './pages/settings/settings.component';
import { PasswordComponent } from './pages/account/password/password.component';
import { RegisterComponent } from './pages/account/register/register.component';
import {
  GoogleSigninButtonModule,
  SocialLoginModule,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import {
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';
import {
  GoogleLoginProviderOverride,
} from './services/googleloginprovider.service';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NewsComponent,
    DestinationsComponent,
    DetailComponent,
    BackButtonDirective,
    CloseMenuOnClickDirective,
    AccountComponent,
    LoginComponent,
    ProfileComponent,
    LogoutButtonDirective,
    MycardComponent,
    FavoritesComponent,
    CitiesComponent,
    NearbyComponent,
    SearchFilterPipe,
    FaqComponent,
    InfoComponent,
    ImpressumComponent,
    TechnischerSupportComponent,
    AgbComponent,
    PrivacyComponent,
    SettingsComponent,
    PasswordComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAuYBmWj-Bq1VVX2j14_4EIey6UoWAh7zg',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    GoogleSigninButtonModule,
    SocialLoginModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: CONFIG,
      useValue: {
        allow_ad_personalization_signals: false,
        anonymize_ip: true,
      },
    },
    { provide: APP_NAME, useValue: 'Ruhr.Topcard App' },
    { provide: APP_VERSION, useValue: '1.0.1' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: 'GOOGLE',
            provider: new GoogleLoginProviderOverride(
              '890619861940-85fkmmdbo286rrv646kitepsn96beaff.apps.googleusercontent.com',
              {
                scopes: 'email profile',
                prompt: '',
                oneTapEnabled: false,
                ux_mode: 'redirect',
                login_uri: 'https://www.ruhrtopcard.de/rest/v1/socialauthgoogleredirect'
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('265448015301730'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    MessagingService,
    ScreenTrackingService,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
